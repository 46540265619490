









































import './scss/BaseActions.scss';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { IAction } from '@/interfaces/IAction';
import debounce from '@/modules/debounce.js';
import { sidebarNamespace } from '@store/modules/sidebar';

@Component({
    name: 'BaseActions',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class BaseActions extends Vue {
    $refs!: {
        actionList: HTMLFormElement,
    }

    @Prop({}) actionList!: IAction[];
    @Prop({ default: true }) closeOnChoose!: boolean;
    @sidebarNamespace.Getter('sidebarShow') sidebarShow!: boolean;

    actionListDisplayed: boolean = false;
    actionListShow: boolean = false;
    selectAnimationDuration: number = 200;
    selectOverflowingLeft: boolean = true;
    minDistanceSelectToWindowBorder: number = 15;
    debounceDelay: number = 300;

    mounted() {
        this.checkAlign();
        window.addEventListener('resize', this.debouncedOnWindowResize)
    }

    @Watch('sidebarShow')
    watcherSidebarShow() {
        this.checkAlign();
    }

    get debouncedOnWindowResize() {
        return debounce(this.checkAlign, this.debounceDelay);
    }

    emitAction(action: IAction) {
        this.$emit('action', action);
    }

    toggleActionList() {
        this.actionListShow ? this.closeSelect() : this.openSelect();
        setTimeout(() => {
            if (this.actionListDisplayed) {
                document.addEventListener('click', this.documentClickHandler);
            }
        }, 0);
    }

    documentClickHandler(): void {
        this.actionListShow = false;
        this.actionListDisplayed = false;
        document.removeEventListener('click', this.documentClickHandler);
    }

    chooseAction(action: IAction) {
        this.emitAction(action);
        if (this.closeOnChoose) {
            this.closeSelect();
        }
    }

    openSelect() {
        this.actionListDisplayed = true;
        this.$nextTick(() => {
            this.actionListShow = true;
        })
    }

    closeSelect() {
        this.actionListShow = false;
        setTimeout(() => {
            this.actionListDisplayed = false;
        }, this.selectAnimationDuration);
        setTimeout(() => {
            if (this.actionListDisplayed) {
                document.removeEventListener('click', this.documentClickHandler);
            }
        }, 0);
    }

    checkAlign() {
        const actionList = this.$refs.actionList;
        this.selectOverflowingLeft = actionList && actionList.getBoundingClientRect().left >= this.minDistanceSelectToWindowBorder;
    }
}
