var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-actions"},[_c('BaseButton',{class:[
            'base-actions__open-button',
            { 'base-actions__open-button--opened': _vm.actionListDisplayed }
        ],attrs:{"type":"border-none"},on:{"click":_vm.toggleActionList}},[_c('svg',{staticClass:"base-actions__three-dots-icon"},[_c('use',{attrs:{"xlink:href":"#icon-three-dots"}})])]),_c('ul',{ref:"actionList",class:[
            'base-actions__action-list',
            { 'base-actions__action-list--displayed': _vm.actionListDisplayed },
            { 'base-actions__action-list--show': _vm.actionListShow },
            { 'base-actions__action-list--overflowing-left': _vm.selectOverflowingLeft },
            { 'base-actions__action-list--overflowing-right': !_vm.selectOverflowingLeft } ]},_vm._l((_vm.actionList),function(action,index){return _c('li',{key:("action" + index),staticClass:"base-actions__action"},[_c('BaseButton',{staticClass:"base-actions__action-button",attrs:{"type":"border-none"},on:{"click":function($event){return _vm.chooseAction(action)}}},[_vm._v(" "+_vm._s(action.label)+" ")])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }